<template>
  <div>
    <!--
    <titleBar
      v-show="!isSettingsHome"
      :title="titleBarTitle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      
    />  -->

    <titleBar
      v-if="isSettingsHome"
      :title="'Billing'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <settingsTabs />
    <div class="contai">
      <!--  
      TBD;

      <br />
      Account balance <br />
      Payment methods <br />
      Recharge <br />
      Active Subscriptions (mix of bot-subs, bundles & creator plans; all have an expiry date, and auto-renew can be
      disabled.) <br />
      Transactions (view all)
      -->

      <div class="section" :class="{ isSettingsHome: isSettingsHome, notHome: !isSettingsHome }">
        <div class="container w1000" v-if="user">
          <h2 class="title is-2">Billing</h2>

          <div class="columns">
            <div class="column">
              <div class="box">
                <h4 class="title is-5">Wallet Balance</h4>
                <h4 class="subtitle is-3"><i class="fas fa-coin coin-icon"></i> 3333 coins</h4>
                <router-link to="/billing/recharge" class="button is-dard is-outlinedNO is-rounded is-small">
                  Recharge wallet
                </router-link>
              </div>
            </div>
            <div class="column">
              <div class="box">
                <h4 class="title is-5">Card on file</h4>
                STRIPE BANNER
                <p>
                  You need to add a credit card to pay for subscriptions and computing fees for your bot. You recharge
                  your wallet however and whenver you want.
                </p>
                <router-link to="/billing/card" class="button is-dard is-outlinedNO is-rounded is-small">
                  Add credit card
                </router-link>
              </div>
            </div>

            <div class="column">
              <div class="box">
                <h4 class="title is-5">Auto-recharge</h4>
                **hidden if no card on file**

                <p>
                  Enable auto-recharge to automatically recharge your account when your balance gets low and never worry
                  about losing service or forgeting to recharge.
                </p>

                <router-link to="/billing/recharge" class="button is-dard is-outlinedNO is-rounded is-small">
                  Enable auto-recharge
                </router-link>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="title is-5">Active Subscriptions</h4>

          <div class="box">
            <div>
              <b-table :data="subscriptions" :mobile-cards="0">
                <b-table-column field="productName" label="Product Name" sortable v-slot="props">
                  <router-link :to="props.row.item.url">{{ props.row.productName }}</router-link>
                </b-table-column>

                <b-table-column field="type" label="Type" sortable v-slot="props">
                  {{ props.row.type }}
                </b-table-column>

                <b-table-column field="expiryDate" label="Renewal date" sortable v-slot="props">
                  <b-tooltip :label="props.row.expiryDate">
                    {{ new Date(props.row.expiryDate).toLocaleDateString("en-US") }}
                  </b-tooltip>
                </b-table-column>

                <b-table-column field="autoRenew" label="Renew" sortable v-slot="props">
                  <b-switch v-model="props.row.autoRenew" @input="showUpdateAlertAndToast(props.row)"></b-switch>
                  <router-link to="/billing/card" class="button is-dard is-outlinedNO is-rounded is-small">
                    Edit / cancel**
                  </router-link>
                </b-table-column>
                <!-- 
                <b-table-column label="Actions" v-slot="props">
                  <b-button @click="renewSubscription(props.row)">Renew</b-button>
                </b-table-column> -->
              </b-table>
              <button @click="viewMoreTransaction">View More</button>
              <button @click="downloadTransactions">Download</button>
            </div>
          </div>
          <hr />
          <h4 class="title is-5">Latest transactions</h4>
          <div class="box">
            <div>
              <b-table :data="transactions" :mobile-cards="0">
                <b-table-column field="date" label="Date" sortable v-slot="props">
                  {{ props.row.date }} localized**
                </b-table-column>

                <b-table-column field="type" label="Type" sortable v-slot="props">
                  {{ props.row.type }}
                </b-table-column>

                <b-table-column field="name" label="Name" sortable v-slot="props">
                  <router-link :to="props.row.item.url">{{ props.row.name }}</router-link>
                </b-table-column>

                <b-table-column field="term" label="term" sortable v-slot="props">
                  {{ props.row.term }}
                </b-table-column>

                <b-table-column field="cost" label="cost (Coins)" sortable numeric v-slot="props">
                  {{ props.row.cost }}
                </b-table-column>

                <b-table-column field="cost" label="Balance after" sortable numeric v-slot="props">
                  {{ props.row.cost }}
                </b-table-column>
              </b-table>
              <button @click="viewMoreTransaction">View More</button>
              <button @click="downloadTransactions">Download</button>
            </div>
          </div>

          <br />
        </div>

        <!--
    <v-row no-gutters>
      <v-col v-if="!isMobile || isSettingsHome" cols="3">
        <v-list>
          <v-list-item
            v-for="(interlocutor, index) in interlocutors"
            :key="index"
            :to="{ name: 'inbox-thread', params: { conversationId: interlocutor.conversationId } }"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ interlocutor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ interlocutor.lastMessage }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="!isSettingsHome || (isMobile && interlocutors.length === 0)" cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

import settingsTabs from "@/components/nav/settingsTabs.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "BotSettings",
  components: {
    settingsTabs,
    // inboxList,
    //inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      user: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      posting: false,
      // loadingOps: true,
      transactions: [
        {
          date: "2023-07-20",
          type: "tip",
          name: "@boby",
          item: {
            url: "/sample-url",
          },
          term: "One-time",
          cost: 50,
        },
        {
          date: "2023-07-20",
          type: "compute",
          name: "Compute (2023-07-19)",
          item: {
            url: "/sample-url",
          },
          term: "One-time",

          cost: 13,
        },

        {
          date: "2023-07-20",
          type: "creator-plan",
          name: "myBot (creator plus)",
          item: {
            url: "/sample-url",
          },
          term: "Monthly",

          cost: 500,
        },
        {
          date: "2023-07-20",
          type: "bundle-subscription",
          name: "Essential bundle",
          item: {
            url: "/sample-url",
          },
          term: "Monthly",
          cost: 900,
        },
        {
          date: "2023-07-20",
          type: "bot-subscription",
          name: "@Proofread",
          item: {
            url: "/sample-url",
          },
          term: "Monthly",
          cost: 100,
        },

        {
          date: "2023-05-20",
          type: "recharge-auto",
          name: "Wallet recharge",
          item: {
            url: "/sample-url",
          },
          term: "Monthly",
          cost: -5000,
        },
        // More transaction objects...
      ],
      subscriptions: [
        {
          productName: "Proofread",
          item: {
            url: "/sample-url",
          },
          type: "Monthly",
          expiryDate: "2023-07-30T00:00:00.000Z",
          autoRenew: true,
        },
        {
          productName: "myBot (creator plus))",
          item: {
            url: "/sample-url",
          },
          type: "Monthly",
          expiryDate: "2023-08-30T00:00:00.000Z",
          autoRenew: false,
        },
        {
          productName: "Essential bundle",
          item: {
            url: "/sample-url",
          },
          type: "Monthly",
          expiryDate: "2023-09-30T00:00:00.000Z",
          autoRenew: true,
        },
        // More subscription objects...
      ],
    };
  },
  mounted() {
    this.loadProfile();
    /*window.API.getFeaturedBots().then((data) => {
      this.interlocutors = data;
      this.loading = true;
      this.loadThread(this.$route.params.userId, this.$route.params.convo);
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
  watch: {
    $route(to, from) {
      //  this.loadThread(to.params.userId, to.params.convo);
    },
  },
  methods: {
    showUpdateAlertAndToast(subscription) {
      alert("todo UPDATE SUB!");
      this.$buefy.toast.open({
        message: "Updated!",
        type: "is-success",
        position: "is-top",
        duration: 5000,
      });
    },
    renewSubscription(subscription) {
      // Logic to renew subscription
    },
    viewMoreTransaction() {
      // Logic to view more transactions
    },
    downloadTransactions() {
      // Logic to download transactions
    },
    loadProfile(index) {
      var id = this.handle; //this.$route.params.userId;

      var opt = {};
      // if is admin, add moderator option.
      /*
      if (this.$store.main.getters.isAdmin) {
        opt = { moderator: true };
      }*/

      var isAdmin = this.$store.main.getters.isAdmin;
      var method = isAdmin ? "userProfileAdmin" : "userProfileOwner";
      window.API[method](id, opt).then((b) => {
        this.user = b;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    resetChanges() {
      this.loadProfile();
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.user;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };

      let method;
      //ad is admin
      if (this.$route.name == "UserSettingsAdmin" && this.$store.main.getters.isAdmin) {
        method = "updateUserAdmin"; //PUT, for admins only
      } else {
        method = "updateUser";
      }

      window.API[method](d.handle, d).then((bot) => {
        this.posting = false;
        //snackbar black buefy
        this.$buefy.snackbar.open({
          message: "Changes saved",
          type: "is-success",
          position: "is-bottom-right",
          actionText: "Close",
          indefinite: false,
        });

        //bot updated!
      });
    },
  },
  computed: {
    menu() {
      var h = this.handle; //this.$route.params.userId;
      const base = "/u/" + h + "/settings/";
      var menu = [
        {
          label: "Edit Profile",
          icon: "fal fa-pencil",
          to: base + "profile",
        },

        {
          label: "Context info",
          icon: "fal fa-analytics",
          to: base + "context",
        },

        {
          label: "Change password",
          icon: "fal fa-key",
          to: base + "pass",
        },

        {
          label: "Create a bot",
          icon: "fal fa-analytics",
          to: "/creators",
          //  to: base + "create",
        },

        /*


        {
          label: "Monetization",
          icon: "fal fa-lock-open",
          to: base + "monetization",
        },

        {
          label: "My bots",
          icon: "fal fa-robot",
          to: base + "messaging",
        },




        {
          label: "Gallery",
          icon: "fal fa-photo",
          to: base + "gallery",
        },

        //
        {
          label: "Edit Readme",
          icon: "fal fa-file",
          to: base + "readme",
        },
*/
        {
          label: "Mod / Admin",
          icon: "fal fa-wrench",
          to: base + "admin",
          isAdmin: true,
        },
      ];
      // filter out admin only items
      menu = menu.filter((m) => {
        if (m.isAdmin && !this.$store.main.getters.isAdmin) return false;
        return true;
      });

      return menu;
    },
    isSettingsHome() {
      return (
        this.$route.name === "UserSettings"
        //this.$route.name === "BotSettingsHome" ||
        //this.$route.name === "BotSettingsEmpty"
      );
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },
    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    handle() {
      return this.$route.params.userId || this.user?.handle || this.$store.main.getters.userId;
    },
    titleBarTitle() {
      if (this.isSettingsHome) return "Chats";
      // if (this.isNewMessage) return this.$route.params.userId + " ✨ ";
      var rb = this.handle;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
      // if (this.user) return this.user.handle;
      ///var rb = this.$route.params.userId;
    },
    title() {
      return this.titleBarTitle + " / DM";
    },
  },
};
</script>

<style scoped>
.zone {
  /* 
  border-radius: 50px;
  background-color: white;
  border: 1px #ccc solid;*/
}

.zone.list {
  /*
  border-radius: 50px;
   background-color: white;
  */
}

/* mobiel selective columns depending on class isSettingsHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isSettingsHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
</style>
